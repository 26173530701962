import React, { FC } from 'react';
import {
    IResourceComponentsProps,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core';
import {
    Checkbox,
    Col,
    Create,
    Form,
    Input,
    Row,
    SaveButton,
    Select,
    Space,
    useForm,
    useSelect,
    DatePicker,
} from '@pankod/refine-antd';

import { ICategory } from 'interfaces';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import hydrateVErrors from '../../utils/validate';
import { removeObject, storeObject } from '../../utils/accessory';

const STORE_KEY: string = 'create';

export const PreliminaryApplicationCreate: FC<
    IResourceComponentsProps
> = () => {
    const t = useTranslate();
    const { replace } = useNavigation();

    const onAfterAdd = (appId: string): void => {
        if (appId) {
            removeObject(STORE_KEY);
            form.resetFields();

            setTimeout(
                () => (window.location.href = `/applications/edit/${appId}`),
                1000
            );
        }
    };

    const errorManipulation = (error: any[]) => {
        return error?.map((i: string) => {
            let [article, message, ...rest] = i.split(' ');
            let text = rest.join(' ');
            return t(`${article} ${message.split('.').at(-1)} ${text}`);
        });
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {
            let payload = data?.data;
            let appId: string = payload?.id;

            appId && onAfterAdd(appId);
        },
        onMutationError: error => {
            storeObject(STORE_KEY, error?.config?.data);

            const validationErrors = hydrateVErrors(error);

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(validationErrors).map((key: string) => {
                let error = validationErrors?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);
        },
        redirect: false,
        successNotification: {
            message: t(
                'notifications.the_download_is_complete_and_wait_for_it_to_be_processed_successfully_check_the_status_of_dossier_processing_after_some_time'
            ),
            type: 'success',
        },
        resource: 'preliminary-request',
    });

    const { selectProps: applicantProps } = useSelect<ICategory>({
        resource: 'dictionary/applicants',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: sponsorProps } = useSelect<ICategory>({
        resource: 'dictionary/sponsors',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: procedureProps } = useSelect<ICategory>({
        resource: 'dictionary/procedures',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: amendmentProps } = useSelect<ICategory>({
        resource: 'dictionary/amendments',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: organizationProps } = useSelect<ICategory>({
        resource: 'dictionary/organizations',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: stateProps } = useSelect<ICategory>({
        resource: 'dictionary/states',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const onValuesChange = () => {
        const errorList = form.getFieldsError();

        Object.keys(errorList).forEach((field, index) => {
            let name = `${errorList?.[index].name}`
                .split(',')
                .map(i => (Number.isInteger(+i) ? +i : i));

            form.setFields([
                {
                    name,
                    errors: [],
                },
            ]);
        });
    };

    const saveButton = {
        ...saveButtonProps,
    };

    return (
        <Create
            actionButtons={
                <Space>
                    <SaveButton {...saveButton} />
                </Space>
            }
            pageHeaderProps={{
                breadcrumb: <TranslatedBreadcrumbs />,
                onBack: () => replace('/preliminiary-applications'),
            }}
        >
            <Form
                {...formProps}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                    reg_num: undefined,
                    reg_date: undefined,
                    is_electronic_form: false,
                    is_mibp: false,
                    medication_name: undefined,
                    release_form: undefined,
                    certificate: undefined,
                    applicant_id: undefined,
                    procedure_id: undefined,
                    state_id: undefined,
                    organization_id: undefined,
                    procedure_desc: undefined,
                    reject_reason: undefined,
                    notification_email: undefined,
                    ct_code: undefined,
                    ct_version: undefined,
                    ct_applicant_id: undefined,
                    sponsor_id: undefined,
                    amendment_id: undefined,
                    notes: undefined,
                    application_notes: undefined,
                    created: undefined,
                    modified: undefined,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reg_num'
                            )}
                            name="reg_num"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reg_date'
                            )}
                            name="reg_date"
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ minWidth: 300 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.electronic_form'
                            )}
                            name="is_electronic_form"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.mibp')}
                            name="is_mibp"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.medication_name'
                            )}
                            name="medication_name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.release_form'
                            )}
                            name="release_form"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.certificate'
                            )}
                            name="certificate"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.applicant'
                            )}
                            name="applicant_id"
                        >
                            <Select {...applicantProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.procedure'
                            )}
                            name="procedure_id"
                        >
                            <Select {...procedureProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.state')}
                            name="state_id"
                        >
                            <Select {...stateProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.organization'
                            )}
                            name="organization_id"
                        >
                            <Select {...organizationProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.procedure_desc'
                            )}
                            name="procedure_desc"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reject_reason'
                            )}
                            name="reject_reason"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.notification_email'
                            )}
                            name="notification_email"
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_code'
                            )}
                            name="ct_code"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_version'
                            )}
                            name="ct_version"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_applicant'
                            )}
                            name="ct_applicant_id"
                        >
                            <Select {...applicantProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.sponsor'
                            )}
                            name="sponsor_id"
                        >
                            <Select {...sponsorProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.amendment'
                            )}
                            name="amendment_id"
                        >
                            <Select {...amendmentProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.notes')}
                            name="notes"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.application_notes'
                            )}
                            name="application_notes"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
