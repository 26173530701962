import React, { FC } from 'react';
import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Spin,
    useSelect,
} from '@pankod/refine-antd';
import { Success } from '../../../FileUploader';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ICategory } from '../../../../interfaces';

interface IPreliminaryApplicationInfoProps {
    isLoading: boolean;
    formProps: any;
    handleFinish: (values: any) => Promise<void>;
    handleSuccess: (id: Success) => void;
    fileLocked: boolean | number;
    message: (any | undefined)[];
    changes: any[];
}

export const PreliminaryApplicationInfo: FC<
    IPreliminaryApplicationInfoProps
> = ({ formProps, isLoading, handleFinish }) => {
    const { t } = useTranslation();

    const { selectProps: applicantProps } = useSelect<ICategory>({
        resource: 'dictionary/applicants',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: procedureProps } = useSelect<ICategory>({
        resource: 'dictionary/procedures',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: stateProps } = useSelect<ICategory>({
        resource: 'dictionary/states',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: organizationProps } = useSelect<ICategory>({
        resource: 'dictionary/organizations',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    return (
        <Spin spinning={isLoading}>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={async values => {
                    await handleFinish({
                        ...values,
                        reg_date: values.reg_date
                            ? dayjs(values.reg_date).format('DD.MM.YYYY')
                            : null,
                    });
                }}
                initialValues={{
                    reg_num: undefined,
                    reg_date: undefined,
                    is_electronic_form: false,
                    is_mibp: false,
                    medication_name: undefined,
                    release_form: undefined,
                    certificate: undefined,
                    applicant_id: undefined,
                    procedure_id: undefined,
                    state_id: undefined,
                    organization_id: undefined,
                    procedure_desc: undefined,
                    reject_reason: undefined,
                    notification_email: undefined,
                    ct_code: undefined,
                    ct_version: undefined,
                    ct_applicant_id: undefined,
                    sponsor_id: undefined,
                    amendment_id: undefined,
                    notes: undefined,
                    application_notes: undefined,
                    is_ectd: undefined,
                    inn: undefined,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reg_num'
                            )}
                            name="reg_num"
                            initialValue={formProps?.initialValues?.reg_num}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reg_date'
                            )}
                            name="reg_date"
                            initialValue={dayjs(
                                formProps?.initialValues?.reg_date,
                                'DD.MM.YYYY'
                            )}
                        >
                            <DatePicker
                                format="DD.MM.YYYY"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.electronic_form'
                            )}
                            name="is_electronic_form"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.mibp')}
                            name="is_mibp"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.medication_name'
                            )}
                            name="medication_name"
                            initialValue={
                                formProps?.initialValues?.medication_name
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.release_form'
                            )}
                            name="release_form"
                            initialValue={
                                formProps?.initialValues?.release_form
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.certificate'
                            )}
                            name="certificate"
                            initialValue={formProps?.initialValues?.certificate}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.applicant'
                            )}
                            name="applicant_id"
                        >
                            <Select {...applicantProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.procedure'
                            )}
                            name="procedure_id"
                        >
                            <Select {...procedureProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.state')}
                            name="state_id"
                        >
                            <Select {...stateProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.organization'
                            )}
                            name="organization_id"
                        >
                            <Select {...organizationProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.procedure_desc'
                            )}
                            name="procedure_desc"
                            initialValue={
                                formProps?.initialValues?.procedure_desc
                            }
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.reject_reason'
                            )}
                            name="reject_reason"
                            initialValue={
                                formProps?.initialValues?.reject_reason
                            }
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.notification_email'
                            )}
                            name="notification_email"
                            initialValue={
                                formProps?.initialValues?.notification_email
                            }
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.notes')}
                            name="notes"
                            initialValue={formProps?.initialValues?.notes}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.application_notes'
                            )}
                            name="application_notes"
                            initialValue={
                                formProps?.initialValues?.application_notes
                            }
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_code'
                            )}
                            name="ct_code"
                            initialValue={formProps?.initialValues?.ct_code}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_version'
                            )}
                            name="ct_version"
                            initialValue={formProps?.initialValues?.ct_version}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.ct_applicant'
                            )}
                            name="ct_applicant_id"
                            initialValue={
                                formProps?.initialValues?.ct_applicant_id
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.sponsor'
                            )}
                            name="sponsor_id"
                            initialValue={formProps?.initialValues?.sponsor_id}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'preliminiary-applications.fields.amendment'
                            )}
                            name="amendment_id"
                            initialValue={
                                formProps?.initialValues?.amendment_id
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.ectd')}
                            name="is_ectd"
                            valuePropName="checked"
                            initialValue={formProps?.initialValues?.is_ectd}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('preliminiary-applications.fields.inn')}
                            name="inn"
                            initialValue={formProps?.initialValues?.inn}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};
