import { useMemo } from 'react';
import i18n from 'i18next';

export const usePageName = (deps: any) => {
    const pagePath = window.location.pathname;
    const replaced = pagePath.split('/').filter(i => i);
    const assembledStr =
        replaced.length > 1 ? replaced.join('-') : replaced.join(' ');

    const pageName = useMemo(
        () =>
            Array.isArray(assembledStr.split('-')) &&
            assembledStr.split('-').length > 1
                ? assembledStr
                      .split('-')
                      .filter((i: string) => isNaN(Number(i)))
                      .map((i: string) =>
                          i18n.exists(`labels.${i}`) ? i18n.t(`labels.${i}`) : i
                      )
                      .join('-')
                : i18n.t(`labels.${assembledStr}`),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [i18n, pagePath, deps, assembledStr]
    );

    return { pageName };
};
