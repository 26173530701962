import React, { useMemo } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
} from '@pankod/refine-core';
import {
    Col,
    getDefaultSortOrder,
    ListButton,
    Row,
    Show,
    Table,
    TextField,
    useTable,
} from '@pankod/refine-antd';

import { IApplication, IRecordFilterVariables } from 'interfaces';
import { useParams } from 'react-router-dom';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';

export const PharmasolShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { id: dictName } = useParams();

    const { tableProps, sorter } = useTable<
        IApplication,
        HttpError,
        IRecordFilterVariables
    >({
        resource: `pharmasol/dictionaries/sync/${dictName}`,
    });

    const columns = useMemo(
        () => tableProps.dataSource?.[0] ?? {},
        [tableProps]
    );

    const renderColumn = (column: string) => {
        return (
            <Table.Column
                dataIndex={column}
                key={column}
                title={t(`pharmasol.fields.${column}`)}
                render={value => <TextField value={value} />}
                defaultSortOrder={getDefaultSortOrder(column, sorter)}
                sorter
            />
        );
    };

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Show
                    pageHeaderProps={{
                        title: t('pharmasol.fields.show'),
                        extra: [<ListButton />],
                        breadcrumb: <TranslatedBreadcrumbs />,
                    }}
                    recordItemId={dictName}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        scroll={{ x: 500 }}
                        bordered
                    >
                        {Object?.keys(columns).map(column =>
                            renderColumn(column)
                        )}
                    </Table>
                </Show>
            </Col>
        </Row>
    );
};
