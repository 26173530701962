import { FC } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    CrudFilters,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    EditButton,
    Col,
    Row,
    Card,
    CreateButton,
} from '@pankod/refine-antd';
import { IRecordFilterVariables, IApplication } from 'interfaces';

import { Filter } from '../../components/applications/Filter';
import buildSubmissionRow from '../../utils/buildSubmissionRow';

export const PreliminaryApplicationList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    interface IColumn {
        id: string;
        reg_num: string;
        reg_date: string;
        medication_name: string;
        applicant_id: number;
        procedure_id: number;
        state_id: number;
    }

    const { tableProps, searchFormProps } = useTable<
        IApplication,
        HttpError,
        IRecordFilterVariables
    >({
        onSearch: params => {
            const filters: CrudFilters = [];
            const { status_id, type_id, query } = params;

            filters.push(
                {
                    field: 'query',
                    operator: 'eq',
                    value: query,
                },
                {
                    field: 'status_id',
                    operator: 'eq',
                    value: status_id,
                },
                {
                    field: 'type_id',
                    operator: 'eq',
                    value: type_id,
                }
            );

            return filters;
        },
        resource: 'preliminary-request',
    });

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title={t('applications.titles.filters')}>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List
                    pageHeaderProps={{
                        extra: [
                            <CreateButton key="create">
                                {t(
                                    'preliminiary-applications.buttons.create_an_application_with_ev'
                                )}
                            </CreateButton>,
                        ],
                        title: t(
                            'preliminiary-applications.preliminiary-applications'
                        ),
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        pagination={{ ...tableProps.pagination }}
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex="medication_name"
                            key="medication_name"
                            title={t('applications.fields.medication_name')}
                            render={(value, row: IColumn) => (
                                <TextField value={value} />
                            )}
                            sorter
                            width={'45%'}
                        />
                        <Table.Column
                            dataIndex=""
                            key=""
                            title={t('applications.fields.moz_data')}
                            render={(value, row: any) => {
                                const val = value
                                    ? buildSubmissionRow(
                                          row,
                                          ['reg_num', 'reg_date'],
                                          ' від '
                                      )
                                    : '';

                                return val && <TextField value={val} />;
                            }}
                            sorter
                            width={'45%'}
                        />
                        <Table.Column
                            title={t('table.actions')}
                            dataIndex="actions"
                            render={(_, record: any) => {
                                let itemId = record.id;

                                return (
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={itemId}
                                    />
                                );
                            }}
                            width={'5%'}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
