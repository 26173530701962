import React, { FC, useEffect } from 'react';
import {
    IResourceComponentsProps,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core';
import {
    Checkbox,
    Col,
    Create,
    Form,
    Input,
    Row,
    SaveButton,
    Select,
    Space,
    useForm,
    useSelect,
} from '@pankod/refine-antd';

import { IAtcCode, ICategory } from 'interfaces';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import hydrateVErrors from '../../utils/validate';
import { CertificatesInCountries } from '../../components/applications/create/CertificatesInCountries';
import { Compositions } from '../../components/applications/create/Compositions';
import { Applications } from '../../components/applications/create/Applications';
import { TaggedInput } from '../../components/TaggedInput';
import { Packages } from 'components/applications/create/Packages';
import {
    formatDate,
    removeObject,
    splitStr,
    storeObject,
} from '../../utils/accessory';
import isJsonString from '../../utils/json-check';

import { ICountryCertificate, IApplication } from '../../interfaces/app';

const STORE_KEY: string = 'create';

export const ApplicationCreate: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace } = useNavigation();

    const onAfterAdd = (appId: string): void => {
        if (appId) {
            removeObject(STORE_KEY);
            form.resetFields();

            setTimeout(
                () => (window.location.href = `/applications/edit/${appId}`),
                1000
            );
        }
    };

    const errorManipulation = (error: any[]) => {
        return error?.map((i: string) => {
            let [article, message, ...rest] = i.split(' ');
            let text = rest.join(' ');
            return t(`${article} ${message.split('.').at(-1)} ${text}`);
        });
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {
            let payload = data?.data;
            let appId: string = payload?.id;

            appId && onAfterAdd(appId);
        },
        onMutationError: error => {
            storeObject(STORE_KEY, error?.config?.data);

            const validationErrors = hydrateVErrors(error);

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(validationErrors).map((key: string) => {
                let error = validationErrors?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);
        },
        redirect: false,
        successNotification: {
            message: t(
                'notifications.the_download_is_complete_and_wait_for_it_to_be_processed_successfully_check_the_status_of_dossier_processing_after_some_time'
            ),
            type: 'success',
        },
    });

    const outputDate = (item: string) => {
        let splited: string = splitStr(item, 'T')?.[0];

        return formatDate(splited);
    };

    useEffect(() => {
        const setFormFields = () => {
            try {
                // @ts-ignore
                if (!form?._init) {
                    return;
                }

                let formValues: any = localStorage.getItem(STORE_KEY) ?? {};
                let parsedValues = isJsonString(formValues)
                    ? JSON.parse(formValues)
                    : formValues;
                let objKeys = Object?.keys(parsedValues);

                for (let key in objKeys) {
                    let name = objKeys[key];
                    let item = parsedValues[name];

                    let value;
                    switch (name) {
                        case 'file_id':
                            value = item;
                            break;
                        case 'certificates_in_countries':
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            value = item?.map(
                                (country: ICountryCertificate) => {
                                    let date = outputDate(
                                        country?.certificateDate
                                    );
                                    return {
                                        certificateDate: date,
                                        certificateNum: country?.certificateNum,
                                        countryId: country?.countryId,
                                    };
                                }
                            );

                            break;
                        case 'applications':
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            value = item?.map((application: IApplication) => {
                                let date = outputDate(
                                    application?.mohApplicationDate
                                );

                                return {
                                    mohApplicationNumber:
                                        application?.mohApplicationNumber,
                                    typeId: application?.typeId,
                                    mohApplicationDate: date,
                                    procedureNotes: application?.procedureNotes,
                                };
                            });
                            break;
                        default:
                            value = item;
                    }

                    if (value) {
                        form.setFieldsValue({
                            [name]: value,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        setFormFields();
    }, [form]);

    const { selectProps: categoryProps } = useSelect<ICategory>({
        resource: 'dictionary/application-types',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: pharmaceuticalProps } = useSelect<ICategory>({
        resource: 'dictionary/pharmaceutical-forms',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: manufacturersProps } = useSelect<ICategory>({
        resource: 'dictionary/manufacturers',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: applicantProps } = useSelect<ICategory>({
        resource: 'dictionary/applicants',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: representativeProps } = useSelect<ICategory>({
        resource: 'dictionary/representative',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: atcCodeProps } = useSelect<IAtcCode>({
        defaultValue: [],
        metaData: {},
        resource: 'dictionary/atc-classifiers',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        queryOptions: {
            select: ({ data, total }) => {
                let processedData = data.map((item: IAtcCode) => {
                    return {
                        id: item.id,
                        name: `${item.code} - ${item.name}`,
                    };
                });

                return {
                    data: processedData,
                    total: total,
                };
            },
        },
    });

    const { selectProps: additionalAtcCodeProps } = useSelect<IAtcCode>({
        defaultValue: [],
        metaData: {},
        resource: 'dictionary/atc-classifiers',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        queryOptions: {
            select: ({ data, total }) => {
                let processedData = data.map((item: IAtcCode) => {
                    return {
                        id: item.id,
                        name: `${item.code} - ${item.name}`,
                    };
                });

                return {
                    data: processedData,
                    total: total,
                };
            },
        },
    });

    const { selectProps: efficiencyProofProps } = useSelect<ICategory>({
        resource: 'dictionary/efficiency-proofs',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: medicationGroupProps } = useSelect<ICategory>({
        resource: 'dictionary/medication-groups',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: countriesProps } = useSelect<ICategory>({
        resource: 'dictionary/countries',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const onValuesChange = () => {
        const errorList = form.getFieldsError();

        Object.keys(errorList).forEach((field, index) => {
            let name = `${errorList?.[index].name}`
                .split(',')
                .map(i => (Number.isInteger(+i) ? +i : i));

            form.setFields([
                {
                    name,
                    errors: [],
                },
            ]);
        });
    };

    const saveButton = {
        ...saveButtonProps,
    };

    return (
        <Create
            actionButtons={
                <Space>
                    <SaveButton {...saveButton} />
                </Space>
            }
            pageHeaderProps={{
                breadcrumb: <TranslatedBreadcrumbs />,
                onBack: () => replace('/applications'),
            }}
        >
            <Form
                {...formProps}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                    compositions: [
                        {
                            number: undefined,
                            activeIngredients: [
                                {
                                    name: undefined,
                                    internationalNameId: undefined,
                                    quantity: undefined,
                                    quantity_unit_id: undefined,
                                },
                            ],
                        },
                    ],
                    packages: [
                        {
                            id: undefined,
                            typeId: undefined,
                            description: undefined,
                            isFinal: false,
                            certificateText: undefined,
                        },
                    ],
                    applications: [
                        {
                            mohApplicationNumber: undefined,
                            mohApplicationDate: null,
                            typeId: undefined,
                            procedureNotes: undefined,
                        },
                    ],
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.name_drug')}
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.previous_name')}
                            name="previous_name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.name_origin')}
                            name="name_origin"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.procedure')}
                            name="type_id"
                        >
                            <Select {...categoryProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('applications.fields.pharmaceutical_form')}
                            name="pharmaceutical_form"
                        >
                            <Select {...pharmaceuticalProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t(
                                'applications.fields.release_form_details'
                            )}
                            name="release_form"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={12} md={4}>
                        <Form.Item
                            label={t(
                                'applications.fields.medical_immunobiological_preparations'
                            )}
                            name="mibp"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>

                    <Col xs={12} md={4}>
                        <Form.Item
                            label={t('applications.fields.domestic')}
                            name="is_domestic"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.group')}
                            name="group_id"
                        >
                            <Select {...medicationGroupProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.doses_of_medication')}
                            name="doses_of_medication"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('applications.fields.primary_package')}
                            name="primary_package"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('applications.fields.external_package')}
                            name="external_package"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('applications.fields.atc_code')}
                            name="atc_code"
                        >
                            <Select {...atcCodeProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('applications.fields.additional_atc_code')}
                            name="additional_atc_code"
                        >
                            <Select
                                {...additionalAtcCodeProps}
                                mode="multiple"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.usage')}
                            name="usage"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.storage_period')}
                            name="storage_period"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.comment')}
                            name="special_notes"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.efficiency_proof')}
                            name="efficiency_proof_id"
                        >
                            <Select {...efficiencyProofProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.storage_conditions')}
                            name="storage_conditions"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'applications.fields.registred_in_countries'
                            )}
                            name="registred_in_countries_ids"
                        >
                            <Select {...countriesProps} mode="multiple" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* Certificates in countries */}
                <CertificatesInCountries />
                {/* Compositions */}
                <Compositions />
                {/* Packages */}
                <Packages />
                {/* Applications */}
                <Applications />
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t(
                                'applications.fields.numbers_previous_registration_certificates'
                            )}
                            name="numbers_previous_registration_certificates"
                        >
                            <TaggedInput
                                onChangeInput={tags => {
                                    form.setFieldsValue({
                                        numbers_previous_registration_certificates:
                                            tags,
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.applicant')}
                            name="applicant"
                        >
                            <Select {...applicantProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.representative')}
                            name="representative_id"
                        >
                            <Select {...representativeProps} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.manufacturers')}
                            name="manufacturers"
                        >
                            <Select {...manufacturersProps} mode="multiple" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
