import React, { useState, useEffect, FC } from 'react';
import {
    IResourceComponentsProps,
    useNotification,
    HttpError,
    useNavigation,
    useOne,
} from '@pankod/refine-core';
import { Edit, ListButton, useForm } from '@pankod/refine-antd';
import { useParams } from 'react-router-dom';
import { Success } from '../../components/FileUploader';
import { api } from '../../utils/api';
import { IApplication } from '../../interfaces';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { PreliminaryApplicationInfo } from '../../components/PreliminaryApplication';

export const PreliminaryApplicationEdit: FC<IResourceComponentsProps> = () => {
    const { open: notifyOpen } = useNotification();
    const { id } = useParams();
    const { t } = useTranslation();
    const { replace } = useNavigation();
    const [pageTitle, setPageTitle] = useState<string | null>('');

    const errorManipulation = (error: any[]) => {
        return error?.map((i: string) => {
            let [article, message, ...rest] = i.split(' ');
            let text = rest.join(' ');
            return t(`${article} ${message.split('.').at(-1)} ${text}`);
        });
    };

    const {
        formProps: applicationProps,
        saveButtonProps,
        formLoading: isLoading,
        form,
    } = useForm<IApplication>({
        action: 'edit',
        resource: 'preliminary-request',
        queryOptions: { retry: false },
        id,
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => {
        let name = applicationProps?.initialValues?.name;
        if (name) {
            setPageTitle(name);
        }
    });

    const { data: tableProps } = useOne<IApplication, HttpError>({
        resource: `preliminary-request`,
        id: `${id}`,
        queryOptions: { retry: false },
    });

    const handleSuccess = (id: Success) => {
        if (id) {
            applicationProps.form?.setFieldsValue({ file_id: id });
        }
    };

    const handleFinish = async (values: any) => {
        try {
            console.log('values', values);

            const formFields = {
                ...values,
            };

            let result = await api.put(
                `/api/preliminary-request/${id}`,
                formFields
            );

            if (result) {
                if (notifyOpen) {
                    notifyOpen({
                        message: t(
                            'notifications.the_download_is_complete_and_wait_for_it_to_be_processed_successfully_check_the_status_of_dossier_processing_after_some_time'
                        ),
                        type: 'success',
                    });
                }
            }
        } catch (e: any) {
            console.error(e);

            let message = e.response.data.message;
            let errCollection = e.response.data.errors;

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(errCollection).map((key: string) => {
                let error = errCollection?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);

            if (notifyOpen) {
                notifyOpen({
                    message: message ?? e,
                    type: 'error',
                });
            }
        }
    };

    const { data: dataSource }: any = { ...tableProps };
    const changes: any[] = dataSource?.changes;
    let paymentCheck = dataSource?.payment_check ?? [];

    const getStatus = (value: string) => {
        switch (value) {
            case 'not_payed':
                return 'не оплачено';
            case 'partial':
                return 'частково оплачено';
            case null:
                return '-';
            default:
                return 'оплачено';
        }
    };

    // @ts-ignore
    const statuses = paymentCheck.map(record => {
        const expertise_status = getStatus(record.expertise);
        const state_collection_status =
            record.state_collection === null
                ? '-'
                : getStatus(record.state_collection);
        const not_expired_status = record.not_expired
            ? 'не прострочено'
            : 'прострочено';

        const status = {
            number: record.number,
            expertise_status,
            state_collection_status,
            not_expired_status: record?.not_expired ? not_expired_status : null,
            date: record.date,
        };

        if (record.parent !== null) {
            // @ts-ignore
            status.is_package = true;
        }

        return status;
    });

    const fileLocked = statuses.filter(
        (status: any) =>
            status.state_collection_status === 'не оплачено' ||
            status.state_collection_status === 'частково оплачено' ||
            status.expertise_status === 'не оплачено' ||
            status.expertise_status === 'частково оплачено' ||
            status.not_expired_status === 'прострочено'
    ).length;

    return (
        <Edit
            saveButtonProps={{ ...saveButtonProps }}
            pageHeaderProps={{
                extra: [<ListButton key="listBtnKey" />],
                breadcrumb: <TranslatedBreadcrumbs />,
                title: `${t(
                    'applications.titles.working_with_the_dossier'
                )} ${pageTitle}`,
                onBack: () => replace('/preliminiary-applications'),
            }}
        >
            <PreliminaryApplicationInfo
                formProps={applicationProps}
                isLoading={isLoading}
                handleFinish={handleFinish}
                message={statuses}
                fileLocked={fileLocked}
                changes={changes}
                handleSuccess={handleSuccess}
            />
        </Edit>
    );
};
