import React, { useState, useEffect, FC } from 'react';
import {
    IResourceComponentsProps,
    useNotification,
    HttpError,
    useNavigation,
    useOne,
} from '@pankod/refine-core';
import { Edit, ListButton, Tabs, useForm } from '@pankod/refine-antd';
import { useParams } from 'react-router-dom';
import { Success } from '../../components/FileUploader';
import { api } from '../../utils/api';
import { IApplication } from '../../interfaces';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';

import {
    ApplicationInfo,
    Changes,
    DocumentHistory,
    Expertises,
    MedicationInfo,
    StateHistory,
    Submissions,
    ValidationResults,
} from 'components/applications';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

type Status = {
    id: number;
    code: string;
    name: string;
};

interface ECTDValidation {
    status: string;
    code: string;
    category: string;
    comment: string;
    criteria: string;
}

interface Structure {
    title: string;
    file_name: string;
    ectd_path: string;
    operation: string;
    uploaded_at: string | null;
    upload_code: number | null;
    upload_text: string | null;
}

export type Submission = {
    created_at: string;
    sequence: string;
    ectd_validation: ECTDValidation[];
    structure: Structure[];
    status: Status;
};

type PrintHistory = {
    code: string;
    name: string;
    created: string;
    id: number;
    url: string;
};

export const ApplicationEdit: FC<IResourceComponentsProps> = () => {
    const { open: notifyOpen } = useNotification();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState<string>('1');
    const { t } = useTranslation();
    const { replace } = useNavigation();
    const [pageTitle, setPageTitle] = useState<string | null>('');

    const {
        formProps: applicationProps,
        saveButtonProps,
        formLoading: isLoading,
    } = useForm<IApplication>({
        action: 'edit',
        resource: 'applications',
        queryOptions: { retry: false },
        id,
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => {
        let name = applicationProps?.initialValues?.name;
        if (name) {
            setPageTitle(name);
        }
    });

    const { data: tableProps } = useOne<IApplication, HttpError>({
        resource: `applications`,
        id: `${id}`,
        queryOptions: { retry: false },
    });

    const handleSuccess = (id: Success) => {
        if (id) {
            applicationProps.form?.setFieldsValue({ file_id: id });
        }
    };

    const handleFinish = async (values: any) => {
        try {
            const formFields = {
                file_id: values.file_id,
            };

            let result = await api.patch(`/api/applications/${id}`, formFields);

            if (result) {
                if (notifyOpen) {
                    notifyOpen({
                        message: t(
                            'notifications.the_download_is_complete_and_wait_for_it_to_be_processed_successfully_check_the_status_of_dossier_processing_after_some_time'
                        ),
                        type: 'success',
                    });
                }
            }
        } catch (e: any) {
            console.error(e);
            let message = e.response.data.message;
            let text = t(`notifications.${message}`);
            if (notifyOpen) {
                notifyOpen({
                    message: text ?? e,
                    type: 'error',
                });
            }
        }
    };

    const handleSwitchTab = (tab: string) => setActiveTab(tab);

    const { data: dataSource }: any = { ...tableProps };
    const expertises: any[] = dataSource?.expertises;
    const ectdErrors: any[] = dataSource?.ectd_errors;
    const stateHistory: any[] = dataSource?.state_history;
    const changes: any[] = dataSource?.changes;
    const submissions: Array<Submission> = dataSource?.submissions;
    const printHistory: Array<PrintHistory> = dataSource?.print_history;
    let paymentCheck = dataSource?.payment_check ?? [];

    const getStatus = (value: string) => {
        switch (value) {
            case 'not_payed':
                return 'не оплачено';
            case 'partial':
                return 'частково оплачено';
            case null:
                return '-';
            default:
                return 'оплачено';
        }
    };

    // @ts-ignore
    const statuses = paymentCheck.map(record => {
        const expertise_status = getStatus(record.expertise);
        const state_collection_status =
            record.state_collection === null
                ? '-'
                : getStatus(record.state_collection);
        const not_expired_status = record.not_expired
            ? 'не прострочено'
            : 'прострочено';

        const status = {
            number: record.number,
            expertise_status,
            state_collection_status,
            not_expired_status: record?.not_expired ? not_expired_status : null,
            date: record.date,
        };

        if (record.parent !== null) {
            // @ts-ignore
            status.is_package = true;
        }

        return status;
    });

    const fileLocked = statuses.filter(
        (status: any) =>
            status.state_collection_status === 'не оплачено' ||
            status.state_collection_status === 'частково оплачено' ||
            status.expertise_status === 'не оплачено' ||
            status.expertise_status === 'частково оплачено' ||
            status.not_expired_status === 'прострочено'
    ).length;

    return (
        <Edit
            saveButtonProps={{
                ...saveButtonProps,
                hidden: activeTab !== '1',
            }}
            pageHeaderProps={{
                extra: [<ListButton key="listBtnKey" />],
                breadcrumb: <TranslatedBreadcrumbs />,
                title: `${t(
                    'applications.titles.working_with_the_dossier'
                )} ${pageTitle}`,
                onBack: () => replace('/applications'),
            }}
        >
            <Tabs defaultActiveKey={activeTab} onChange={handleSwitchTab}>
                <TabPane
                    tab={t('applications.titles.application_information')}
                    key="1"
                >
                    <ApplicationInfo
                        formProps={applicationProps}
                        isLoading={isLoading}
                        handleFinish={handleFinish}
                        message={statuses}
                        fileLocked={fileLocked}
                        changes={changes}
                        handleSuccess={handleSuccess}
                    />
                </TabPane>
                <TabPane tab={t('applications.fields.medication')} key="2">
                    <MedicationInfo
                        formProps={applicationProps}
                        isLoading={isLoading}
                    />
                </TabPane>
                <TabPane
                    tab={t('applications.titles.validation_results')}
                    key="3"
                >
                    <ValidationResults
                        data={ectdErrors}
                        group="applications.titles"
                    />
                </TabPane>
                {false && (
                    <TabPane
                        tab={t('applications.titles.change_history')}
                        key="4"
                    >
                        <Changes data={changes} group="applications.titles" />
                    </TabPane>
                )}
                <TabPane tab={t('applications.titles.expertises')} key="5">
                    <Expertises data={expertises} group="applications.titles" />
                </TabPane>
                <TabPane tab={t('applications.titles.state_history')} key="6">
                    <StateHistory
                        data={stateHistory}
                        group="applications.titles"
                    />
                </TabPane>
                <TabPane
                    tab={t('applications.titles.document_history')}
                    key="7"
                >
                    <DocumentHistory
                        data={printHistory}
                        group="applications.titles"
                    />
                </TabPane>
                <TabPane tab={t('applications.titles.submissions')} key="8">
                    <Submissions
                        submissions={submissions}
                        status={dataSource?.status}
                    />
                </TabPane>
            </Tabs>
        </Edit>
    );
};
