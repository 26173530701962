const access = {
    regular: [
        'preliminiary-applications',
        'applications',
        'applications-external',
        'form',
        'history',
        'clinicaltrial_items',
        'menuforms',
    ],
    expert: [
        'preliminiary-applications',
        'applications',
        'applications-external',
        'form',
        'history',
        'clinicaltrial_items',
        'menuforms',
        'activity',
        'dictionaries',
    ],
};

export default access;
